import React from 'react';
import CookieConsent from 'react-cookie-consent';

import { CookieStyles } from './Cookie.atom';

// Atoms
import { Text } from '@/fatcat-ui-library/components/Atoms/Text';

const translation = {
	en: {
		msg: 'Wio uses cookies to ensure you get the best experience.',
		buttonText: 'Accept',
	},
	ar: {
		msg: 'يستخدم Wio ملفات تعريف الارتباط لضمان حصولكم على أفضل تجربة.',
		buttonText: 'قبول',
	},
};

type TCookie = {
	locale: string
	dark: boolean
	setCookie: (e: boolean) => void
}

const Cookie: React.FC<TCookie> = ({ locale, dark, setCookie }) => {
	const copy = translation[locale];

	return (
		<>
			<CookieStyles isAr={locale === 'ar'} dark={dark} />
			<CookieConsent
				location="bottom"
				buttonText={copy.buttonText}
				cookieName="wioBankCookieConsent"
				expires={365}
				overlayClasses="CookieConsent--container"
				containerClasses="CookieConsent--wrapper"
				contentClasses="CookieConsent--content"
				buttonClasses="CookieConsent--accept-button"
				buttonWrapperClasses="CookieConsent--button-wrapper"
				overlay
				onAccept={() => { setCookie(true); }}
			>
				<Text
					$textColor="darkBlue"
					$fontSize="s26"
					$textAlign="center"
					$tablet={[{ fontSize: 's18' }]}
				>
					{copy.msg}
				</Text>
			</CookieConsent>
		</>
	);
};

export default Cookie;
