export const translationCopy = {
	en: {
		size: {
			title: 'Change Font Size',
			item: 'A',
		},
		contrast: {
			title: 'Change Contrast',
			items: [
				{
					name: 'Wio Colors',
					value: 'light',
				},
				{
					name: 'High Contrast',
					value: 'dark',
				},
			],
		},
	},
	ar: {
		size: {
			title: 'تغيير حجم الخط',
			item: 'أ',
		},
		contrast: {
			title: 'تغيير تباين الألوان',
			items: [
				{
					name: ' تباين عال',
					value: 'light',
				},
				{
					name: 'ألوان “ويو”',
					value: 'dark',
				},
			],
		},
	},
};
