import { css } from 'styled-components';

export const TEXT_STYLE = {
	h1: css`
		${props => props.theme.textSize.h1};
		${props => props.theme.media.tablet} {
			${props => props.theme.textSize.h1Tablet};
		}
	`,
	h2: css`
		${props => props.theme.textSize.h2};
		${props => props.theme.media.tablet} {
			${props => props.theme.textSize.h2Tablet};
		}
	`,
	h3: css`
		${props => props.theme.textSize.h3};
		${props => props.theme.media.tablet} {
			${props => props.theme.textSize.h3Tablet};
		}
	`,
	h4: css`
		${props => props.theme.textSize.h4};
		${props => props.theme.media.tablet} {
			${props => props.theme.textSize.h4Tablet};
		}
	`,
	paragraph: css`
		${props => props.theme.textSize.paragraph};
		${props => props.theme.media.tablet} {
			${props => props.theme.textSize.paragraphTablet};
		}
	`,
};
