import { css, DefaultTheme } from 'styled-components';

export const HOVER_STYLE = {
	textPurple: css`
		color: ${props => props.theme.color.purple};
	`,
	textLinen: css`
		color: ${props => props.theme.color.linen};
	`,
	textDarkBlue100: css`
		color: ${props => props.theme.color.darkBlue100};
	`,
	backgrounColor: (value : keyof DefaultTheme['color']) => css`
		background-color: ${props => props.theme.color[value]};
`,
};
