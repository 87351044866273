import { keyframes } from 'styled-components';

const fadeInFromRight = keyframes`
    from {
		opacity: 0;
		transform: translateX(10%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
`;

const fadeIn = keyframes`
	from {
		opacity: 0;

	}
	to {
		opacity: 1;

	}
`;

const fadeOut = keyframes`
	from {
		opacity: 1;

	}
	to {
		opacity: 0;
	}
`;

const spin = keyframes`
	from {
		transform: rotate(0deg);

	}
	to {
		transform: rotate(360deg);
	}
`;

const popUp = keyframes`
	from {
		transform: scale(0.5);

	}
	to {
		transform: scale(1);
	}
`;

export const ANIMATIONS = {
	fadeInFromRight,
	fadeIn,
	fadeOut,
	spin,
	popUp,
};
