import { css } from 'styled-components';

export const INPUT_STYLE = {
	base: css`
		border-radius: 12px;
		appearance: none;
		border: 2px solid ${props => props.theme.color.white};
		padding: ${props => props.theme.space.s12} ${props => props.theme.space.s14} ;
		margin: 0;
		color: ${props => props.theme.textColor.darkBlue};
		background: ${props => props.theme.color.white};
		position: relative;
		outline: 0;
		font-size: ${props => props.theme.fontSize.s16};
		width: 100%;
		line-height: 1.4;

		&:hover,
		&:focus {
			border-color: ${props => props.theme.color.darkBlue};
		}

		${props => props.theme.media.tablet} {
			width: 100%;
			max-width: unset;
			padding: ${props => props.theme.space.s12};
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active  {
			-webkit-box-shadow: 0 0 0 40px transparent inset !important;
			-webkit-text-fill-color: ${props => props.theme.color.darkBlue} !important;
		}

		${props => props.theme.media.largeTablet} {

			&::placeholder {
				color: transparent;
			}
			&:-ms-input-placeholder {
				color: transparent;
			}

			@supports (not (-ms-ime-align:auto)) {
				&:-webkit-autofill ~ label,
				&:-webkit-autofill:hover ~ label,
				&:-webkit-autofill:focus ~ label,
				&:-webkit-autofill:active ~ label,
				&:focus ~ label,
				&:not(:placeholder-shown) ~ label {
					display: none;
				}
			}
		}
    `,
};
