import { createGlobalStyle } from 'styled-components';

export const HTMLStyle = createGlobalStyle`
	body.html-block-scrolling {
		${props => props.theme.media.desktop} {
			overflow: hidden;
		}
	}

	html[lang=ar] {
		* {
			font-family: ${props => props.theme.fontFamily.secondary};
		}
		h1 {
			line-height: 1.1;
		}
		p,
		li,
		input,
		span {
			direction: rtl;
		}
	}
	.dark-filter {
		filter: grayscale(1);
	}

	:focus {
		position: relative;
		&:before {
			content: ' ';
			position: absolute;
			left: -4px;
			right: -4px;
			top: -4px;
			bottom: -4px;
			border-radius: 4px;
			box-shadow: 0 0 0 1px ${props => props.theme.color.darkBlue}, 0 0 0 2px ${props => props.theme.color.white};
			outline: none;
			transition: none;
			box-sizing: border-box;
			pointer-events: none;
		}
	}


	:focus-visible {
		position: relative;
		outline: unset;
		&:before {
			content: ' ';
			position: absolute;
			left: -4px;
			right: -4px;
			top: -4px;
			bottom: -4px;
			border-radius: 4px;
			box-shadow: 0 0 0 1px ${props => props.theme.color.darkBlue}, 0 0 0 2px ${props => props.theme.color.white};
			outline: none;
			transition: none;
			box-sizing: border-box;
			pointer-events: none;
		}
	}

	:focus:not(:focus-visible) {
		position: relative;
		&:before {
			content: unset;
		}
	}

	#gatsby-focus-wrapper::before {
		content: unset;
	}
`;
