import { css } from 'styled-components';

export const transitionStyle = css`
	transition: 0.2s all linear;
`;

export const SPACE = {
	's-4': '-4px',
	's-6': '-6px',
	's-8': '-8px',
	's-10': '-10px',
	's-220': '-220px',
	's-262': '-262px',
	's-308': '-308px',
	s2: '2px',
	s10: '10px',
	s14: '14px',
	s18: '18px',
	s30: '30px',
	s36: '36px',
	s46: '46px',
	s60: '60px',
	s84: '84px',
	s90: '90px',
	s100: '100px',
	s110: '110px',
	s156: '156px',
	s192: '192px',
	s248: '248px',
	s296: '296px',
	s340: '340px',
	s400: '400px',
};

export const COLOR = {
	black: '#000000',
	blackGlass: '#000c', // black with opacity 0.8
	greyGlass: 'rgb(143, 143, 143, 0.62)',
	black100: '#6C6B66',
	header: '#FFFFFF',
	headerDark: '#0F1A38',
	white: '#FFFFFF',
	red: '#fc1e1e',
	white100: '#EDEDED',
	white200: '#F9F8F8',
	white300: '#F4F4EC',
	white400: '#F6F6F6',
	white500: '#F8F8F9',
	white600: '#F7F7F4',
	darkBlue: '#0F1A38',
	darkBlue80: '#101B39',
	darkBlue90: '#1E185B',
	darkBlue100: '#17223F',
	grey: '#BEBEBE',
	grey100: '#DEDEDC',
	grey200: '#BEA197',
	grey300: '#EFEEE8',
	grey400: '#A2A4A8',
	grey500: '#898986',
	grey600: '#585858',
	grey700: '#AFB3BD',
	grey800: '#EBEAE5',
	grey900: '#EEEDE7',
	darkGrey: '#5F667A',
	lightGrey: '#CFD1D7',
	linen: '#F7D7D0',
	linenLight: '#FFECE8',
	pink: '#FAE4E0',
	darkPurple: '#450EBC',
	purple: '#5500F9',
	lightPurple: '#F9F6FF',
	newGrey: '#878D9C',

	purple100: '#F4EFFD',
	purple200: '#DDCCFE1A',
	purple300: '#FAF7FF',
	purple400: '#C6AAFD',
	blue: '#99AAD6',
	purple500: '#C6AAFD57',
	grey850: '#878C9B',
	grey670: '#F0F0ED',
};

export const TEXT_COLOR = {
	black: COLOR.black,
	red: COLOR.red,
	newGrey: COLOR.newGrey,
	black100: COLOR.black100,
	grey: COLOR.grey,
	grey100: COLOR.grey100,
	grey300: COLOR.grey300,
	grey500: COLOR.grey500,
	grey600: COLOR.grey600,
	grey700: COLOR.grey700,
	white: COLOR.white,
	darkBlue: COLOR.darkBlue,
	darkBlue100: COLOR.darkBlue100,
	darkGrey: COLOR.darkGrey,
	linen: COLOR.linen,
	purple: COLOR.purple,
	grey400: COLOR.grey400,
	grey850: COLOR.grey850,
};

export const MEDIA = {
	desktopBig: '@media screen and (max-width: 1680px)',
};

export const SECTION_WIDTH = {
	normal: '1460px',
	wide: '1552px',
};

export const FONT_FAMILY = {
	main: 'AktivGrotesk',
	secondary: 'Aktiv Grotesk Trial',
};

// add size also to FONT_SIZE_BIG
export const FONT_SIZE = {
	s8: '0.5rem',
	s10: '0.625rem',
	s11: '0.6875rem',
	s22: '1.325rem',
	s26: '1.625rem',
	s30: '1.875rem',
	s36: '2.25rem',
	s38: '2.375rem',
	s40: '2.5rem',
	s44: '2.75rem',
	s50: '3.125rem',
	s70: '4.375rem',
	s76: '4.75rem',
	s90: '5.625rem',
	s100: '6.25rem',
	s124: '7.75rem',
};

// font-size 12px-36px should add 8px to size
export const FONT_SIZE_BIG = {
	s8: '0.5rem',
	s10: '0.625rem',
	s14: '1.375rem',
	s16: '1.5rem',
	s18: '1.625rem',
	s20: '1.75rem',
	s24: '2rem',
	s26: '2.125rem',
	s28: '2.25rem',
	s30: '2.375rem',
	s32: '2.5rem',
	s36: '2.75rem',
	s38: '2.375rem',
	s40: '2.5rem',
	s44: '2.75rem',
	s50: '3.125rem',
	s76: '4.75rem',
	s90: '5.625rem',
	s100: '6.25rem',
	s124: '7.75rem',
};

export const LINE_HEIGHT = {
	p14: '0.875rem',
	p20: '1.25rem',
	p22: '1.375rem',
	p26: '1.625rem',
	p28: '1.75rem',
	p30: '1.875rem',
	p32: '2rem',
	p34: '2.125rem',
	p38: '2.375rem',
	p40: '2.5rem',
	p44: '2.75rem',
	p50: '3.125rem',
	p80: '5rem',
	p124: '7.75rem',
};

export const SectionStyle = css`
	${props => css`
		padding: 0 ${props => props.theme.space.s64};

		${props.theme.media.largeTablet} {
			padding-left: ${props.theme.space.s44};
			padding-right: ${props.theme.space.s44};
		}

		${props.theme.media.tablet} {
			padding-left: ${props.theme.space.s32};
			padding-right: ${props.theme.space.s32};
		}

		${props.theme.media.mobile} {
			padding: 0 ${props.theme.space.s16};
		}
	`}
`;
