import React, { useContext } from 'react';

import { AppContext } from '../../../layouts';

// Atoms
import { Text } from '@/fatcat-ui-library/components/Atoms/Text';
import { Flex } from '@/fatcat-ui-library/components/Atoms/Flex';
import { Image } from '@/fatcat-ui-library/components/Atoms/Image';
import { Wrapper } from '@/fatcat-ui-library/components/Atoms/Wrapper';
import { Button } from '@/fatcat-ui-library/components/Atoms/Button';
import BoxButton from '../../Atoms/BoxButton';

// Data
import { translationCopy } from './translation';

// Assets
import cross from '../../../assets/images/icons/cross.svg';

type TAccessibilityModal = {
	locale: string
}

const AccessibilityModal: React.FC<TAccessibilityModal> = ({ locale }) => {
	const {
		themeFontSize,
		setThemeFontSize,
		themeColor,
		setThemeColor,
		setAccessibilityModal,
	} = useContext(AppContext);

	const copy = translationCopy[locale];
	const isAr = locale === 'ar';

	const handleThemeChange = (e) => {
		setThemeColor(e.target.value);
	};
	const handleThemeFont = (e) => {
		setThemeFontSize(e.currentTarget.dataset.name);
	};

	const handleCloseModal = (e) => {
		setAccessibilityModal(false);
	};

	return (
		<>
			<Wrapper
				$position="fixed"
				$zIndex={120}
				$height="100vh"
				$top="0"
				$left="0"
				$backgroundColor="blackGlass"
				onClick={handleCloseModal}
				style={{ filter: 'blur(4px)' }}
			/>
			<Flex
				className={themeColor === 'dark' && 'dark-filter'}
				as="dialog"
				$position="fixed"
				$zIndex={121}
				$top="50%"
				$left="50%"
				$transform="translate(-50%, -50%)"
				$direction="column"
				$border="none"
				$boxShadow="0 0 50px rgba(0, 0, 0, 0.2)"
				$padding={['t24', 'b48']}
				$width="50%"
				$minWidth="600px"
				$maxWidth="1025px"
				$margin={['r0', 'l0']}
				$backgroundColor="white400"
				$borderRadius="20px"
				$largeTablet={[{ width: 'calc(100% - 40px)' }, { maxWidth: '600px' }, { minWidth: 'unset' }]}
			>
				<Wrapper
					$width="fit-content"
					$height="fit-content"
					$alignSelf={isAr ? 'flex-start' : 'flex-end'}
					$margin={isAr ? ['rauto', 'b16'] : ['lauto', 'b16']}
					tabIndex={0}
					aria-label="close accessibility modal"
					onClick={handleCloseModal}
				>
					<Image
						src={cross}
						$width="22px"
						$height="22px"
						$hover={['pointer']}
						$largeTablet={[{ width: '16px' }, { height: '16px' }]}
					/>
				</Wrapper>
				<Flex
					$direction="column"
					$alignItems="center"
				>
					<Text
						$fontSize="s36"
						$textAlign="center"
						$textColor="darkBlue"
						$margin={['b24']}
						$largeTablet={[{ fontSize: 's24' }]}
					>
						{copy.size.title}
					</Text>
					<Flex
						$justifyContent="center"
						$gap="20px"
						$margin={['b56']}
						$direction={isAr ? 'row-reverse' : 'row'}
					>
						<Button
							onClick={handleThemeFont}
							$variant="accesbilityBtn"
							$active={themeFontSize === 'small'}
							data-name="small"
						>
							<Text
								$fontSize="s36"
								$largeTablet={[{ fontSize: 's24' }]}
							>
								{copy.size.item}
							</Text>
						</Button>
						<Button
							onClick={handleThemeFont}
							$variant="accesbilityBtn"
							$active={themeFontSize === 'big'}
							data-name="big"
						>
							<Text
								$fontSize="s50"
								$largeTablet={[{ fontSize: 's36' }]}
							>
								{copy.size.item}
							</Text>
						</Button>
					</Flex>
					<Text
						$fontSize="s36"
						$textColor="darkBlue"
						$textAlign="center"
						$margin={['b24']}
						$largeTablet={[{ fontSize: 's24' }]}
					>
						{copy.contrast.title}
					</Text>
					<Flex
						$justifyContent="center"
					>
						<Flex
							$width="fit-content"
							$gap="50px"
							$tablet={['column', { gap: '20px' }]}
						>
							{copy.contrast.items.map(item => (
								<BoxButton
									key={item.name}
									type="radio"
									inputSize="26px"
									fontSize="s24"
									id={item.value}
									name="theme_color"
									boxColor="darkBlue"
									textColor="darkBlue"
									value={item.value}
									onChange={handleThemeChange}
									text={item.name}
									checked={themeColor === item.value}
								/>
							))}
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

AccessibilityModal.defaultProps = {
};

export default AccessibilityModal;
