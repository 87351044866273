import React, { useState } from 'react';
import { HTMLStyle } from './globalStyle';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';

// Atoms
import GlobalStyle from '@/fatcat-ui-library/components/Atoms/GlobalStyle';

// Fonts
import '../assets/fonts/stylesheet.css';
import '../assets/fonts/AktivGrotesk-Trial/stylesheet.css';

// Themes
import { UIThemeProvider, FatCatTheme } from '@/fatcat-ui-library/theme/theme';

import AccessibilityModal from '../components/Organisms/AccessibilityModal/AccessibilityModal';
import CookieConsent from '../components/Molecules/Cookie/Cookie';
import PromoBanner from '../components/Molecules/PromoBanner/PromoBanner';
import SayHelloModal from '../components/Sections/Homepage/SayHelloModal/SayHelloModal';

import {
	ANIMATIONS,
	BUTTON_STYLE,
	COLOR,
	FONT_FAMILY,
	FONT_SIZE,
	FONT_SIZE_BIG,
	HOVER_STYLE,
	LINE_HEIGHT,
	LINK_STYLE,
	SECTION_WIDTH,
	SPACE,
	SectionStyle,
	SideSpace,
	TEXT_COLOR,
	TEXT_SIZE,
	TEXT_STYLE,
	MEDIA,
	INPUT_STYLE,
	ULLIST_STYLE,
	OLLIST_STYLE,
} from '../theme';

declare module 'styled-components' {
	export interface DefaultTheme extends FatCatTheme {
		animation: FatCatTheme['animation'] & typeof ANIMATIONS
		backgroundColor: FatCatTheme['backgroundColor'] & typeof COLOR
		buttonStyle: FatCatTheme['buttonStyle'] & typeof BUTTON_STYLE
		color: FatCatTheme['color'] & typeof COLOR
		fontFamily: typeof FONT_FAMILY
		fontSize: FatCatTheme['fontSize'] & typeof FONT_SIZE
		lineHeight: FatCatTheme['lineHeight'] & typeof LINE_HEIGHT
		linkStyle: FatCatTheme['linkStyle'] & typeof LINK_STYLE
		sectionWidth: FatCatTheme['sectionWidth'] & typeof SECTION_WIDTH
		sideSpace: FatCatTheme['sideSpace'] | SideSpace
		space: FatCatTheme['space'] & typeof SPACE
		textColor: FatCatTheme['textColor'] & typeof TEXT_COLOR
		textSize: FatCatTheme['textSize'] & typeof TEXT_SIZE
		textStyle: FatCatTheme['textStyle'] & typeof TEXT_STYLE
		hover: FatCatTheme['hover'] & typeof HOVER_STYLE
		media: FatCatTheme['media'] & typeof MEDIA
		inputStyle: FatCatTheme['inputStyle'] & typeof INPUT_STYLE
		ulListStyle: FatCatTheme['ullistStyle'] & typeof ULLIST_STYLE
		olListStyle: FatCatTheme['ollistStyle'] & typeof OLLIST_STYLE
	}
}

const newTheme = {
	ullistStyle: ULLIST_STYLE,
	ollistStyle: OLLIST_STYLE,
	animation: ANIMATIONS,
	backgroundColor: COLOR,
	buttonStyle: BUTTON_STYLE,
	color: COLOR,
	fontFamily: FONT_FAMILY,
	fontSize: FONT_SIZE,
	lineHeight: LINE_HEIGHT,
	linkStyle: LINK_STYLE,
	sectionLayout: SectionStyle,
	sectionWidth: SECTION_WIDTH,
	space: SPACE,
	textColor: TEXT_COLOR,
	textSize: TEXT_SIZE,
	textStyle: TEXT_STYLE,
	hover: HOVER_STYLE,
	media: MEDIA,
	inputStyle: INPUT_STYLE,
};

export const AppContext = React.createContext(null);
type TLayot = {
	path: string
	pageContext: {
		locale: string
	}
};

const Index: React.FC<TLayot> = (props) => {
	const { children, pageContext, path } = props;

	const [accessibilityModal, setAccessibilityModal] = useState(false);
	const [sayHelloModal, setSayHelloModal] = useState(false);
	const [themeFontSize, setThemeFontSize] = useState('small');
	const [themeColor, setThemeColor] = useState('light');
	const [cookie, setCookie] = useState(false);
	const [investorRelationsYear, setInvestorRelationsYear] = useState<'2023'| '2024'>('2024');

	const toggleSayHelloModalOpen = (isAr:boolean) => {
		if (isAr) {
			if (sessionStorage.getItem('arSayHelloModalOpenedOnce') === 'true') return;
			sessionStorage.setItem('arSayHelloModalOpenedOnce', 'true');
		} else {
			if (sessionStorage.getItem('engSayHelloModalOpenedOnce') === 'true') return;
			sessionStorage.setItem('engSayHelloModalOpenedOnce', 'true');
		}

		setSayHelloModal(prev => !prev);
	};

	const context = {
		themeFontSize,
		setThemeFontSize,
		themeColor,
		setThemeColor,
		accessibilityModal,
		setAccessibilityModal,
		sayHelloModal,
		toggleSayHelloModalOpen,
		investorRelationsYear,
		setInvestorRelationsYear,
	};

	newTheme.fontSize = themeFontSize === 'small' ? FONT_SIZE : FONT_SIZE_BIG;
	const locale = pageContext.language;
	return (
		<UIThemeProvider theme={newTheme}>
			<IntlProvider locale={locale}>
				<AppContext.Provider value={context}>
					<GlobalStyle />
					<HTMLStyle />
					<Helmet>
						<script>
							{`
						(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-NLSLMSJ');
						`}
						</script>
					</Helmet>
					<noscript>
						<iframe
							title="Google Tag Manager"
							src="https://www.googletagmanager.com/ns.html?id=GTM-NLSLMSJ"
							height="0"
							width="0"
							style={{ display: 'none', visibility: 'hidden' }}
						/>
					</noscript>
					<CookieConsent locale={locale} dark={themeColor === 'dark'} setCookie={setCookie} />
					{/* {path !== '/upload/' && path !== '/' && path !== '/ar' && path !== '/ar/' && <PromoBanner locale={locale} dark={themeColor === 'dark'} cookie={cookie} />} */}
					{children}
					{accessibilityModal && <AccessibilityModal locale={locale} />}
					{/* {sayHelloModal && <SayHelloModal locale={locale} />} */}
				</AppContext.Provider>
			</IntlProvider>
		</UIThemeProvider>
	);
};

export default Index;
