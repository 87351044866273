import { css } from 'styled-components';

export const BUTTON_STYLE = {
	base: css`
        color: ${props => props.color ? props.color : props.theme.color.white};
		padding: ${props => props.theme.space.s20} ${props => props.theme.space.s32};
		border-radius: 24px;
		border: 1px solid ${props => props.color ? props.color : props.theme.color.white};
		${props => props.theme.textSize.button};
		font-weight: normal;
		min-width: 150px;

		${props => props.theme.media.tablet} {
			font-size: ${props => props.theme.fontSize.s16};
			padding: ${props => props.theme.space.s12} ${props => props.theme.space.s32};
			min-width: 120px;
		}
	`,
	linen: css` // used in header
		display: flex;
		align-items: center;
		border-radius: 10px;
		background-color: ${props => props.theme.color.linen};
		color: ${props => props.theme.textColor.darkBlue};
		padding: ${props => props.theme.space.s8} ${props => props.theme.space.s12};
		line-height: ${props => props.theme.lineHeight.s100};
		font-size: ${props => props.theme.fontSize.s16};
		font-weight: normal;
		${props => props.theme.media.mobile} {
			font-size: ${props => props.theme.fontSize.s14};
			padding: ${props => props.theme.space.s8};
		}
	`,
	linenBig: css`
		width: 100%;
		min-height: 74px;
		color: ${props => props.theme.color.darkBlue};
		background-color: ${props => props.theme.color.linen};
		padding: ${props => props.theme.space.s24} ${props => props.theme.space.s44} ${props => props.theme.space.s24} ${props => props.theme.space.s28};
		font-size: ${props => props.theme.fontSize.s24};
		line-height: ${props => props.theme.lineHeight.p26};
		border-radius: 10px;
		font-weight: normal;
		min-width: 150px;
		transition: all 0.3s ease-in-out;

		&:hover {
			background-color: ${props => props.theme.color.white};
		}

		${props => props.theme.media.largeTablet} {
			min-height: 40px;
			max-width: 240px;
			font-size: ${props => props.theme.fontSize.s18};
			padding: ${props => props.theme.space.s10} ${props => props.theme.space.s14};
		}

	`,
	darkBlueBig: css`
		width: 100%;
		min-height: 74px;
		color: ${props => props.theme.color.white};
		background-color: ${props => props.theme.color.darkBlue};
		padding: ${props => props.theme.space.s24} ${props => props.theme.space.s44} ${props => props.theme.space.s24} ${props => props.theme.space.s28};
		font-size: ${props => props.theme.fontSize.s24};
		line-height: ${props => props.theme.lineHeight.p26};
		border-radius: 10px;
		font-weight: normal;
		min-width: 150px;
		transition: all 0.3s ease-in-out;

		&:hover {
			background-color: #586280;
		}

		${props => props.theme.media.largeTablet} {
			min-height: 40px;
			max-width: 240px;
			font-size: ${props => props.theme.fontSize.s18};
			padding: ${props => props.theme.space.s10} ${props => props.theme.space.s14};
		}

	`,
	whitePurple: css`
		width: 100%;
		min-height: 74px;
		color: ${props => props.theme.color.purple};
		background-color: ${props => props.theme.color.white};
		padding: ${props => props.theme.space.s24} ${props => props.theme.space.s44} ${props => props.theme.space.s24} ${props => props.theme.space.s28};
		font-size: ${props => props.theme.fontSize.s14};
		line-height: ${props => props.theme.lineHeight.s133};
		border-radius: 10px;
		font-weight: normal;
		min-width: 150px;
		transition: all 0.3s ease-in-out;

		&:hover {
			background-color: #e9e9e9;
		}

		${props => props.theme.media.largeTablet} {
			min-height: 40px;
			max-width: 240px;
			font-size: ${props => props.theme.fontSize.s18};
			padding: ${props => props.theme.space.s10} ${props => props.theme.space.s14};
		}

	`,
	header: css`
		font-size: ${props => props.theme.fontSize.s16};
		line-height: ${props => props.theme.lineHeight.p20};
		border: none;
		font-weight: normal;
		padding: 0 10px;
	`,
	accesbilityBtn: css`
		font-weight: normal;
		border: ${props => `1px solid ${props.theme.color.darkBlue}`};
		border-radius: 20px;
		min-width: 165px;
		min-height: 88px;
		background-color: ${props => props.$active ? props.theme.color.linen : props.theme.color.white};

		${props => props.theme.media.largeTablet} {
			min-width: 85px;
			min-height: 60px;
		}
	`,
	pricing: css`
		font-weight: bold;
		font-size: 24px;
		display: flex;
		gap: 4px;
		color: ${props => props.theme.color.linen};
		min-width: 85px;
		min-height: 50px;
		background-color: ${props => props.theme.color.darkBlue};
		border-radius: 6px;
		padding: 6px 14px;
		align-items: center;
		${props => props.theme.media.largeTablet} {
			min-height: 40px;
			font-size: 14px;
			padding: 6px 10px;
			img {
				height: 0.8em;
			}
		}
		${props => props.theme.media.tablet} {
			min-height: 20px;
			font-size: 10px;
			padding: 6px;
		}
		/* border: ${props => `1px solid ${props.theme.color.darkBlue}`};
		min-width: 165px;
		min-height: 88px;

		 */
	`,
	noStyle: css`
		font-weight: normal;
	`,
};
