import { css } from 'styled-components';

// unused key: button, link
export const TEXT_SIZE = {
	h1B: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s124};
		line-height: ${props => props.theme.lineHeight.p124};
	`,
	h1: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s100};
		line-height: ${props => props.theme.lineHeight.s100};
	`,
	h1Tablet: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s40};
	`,
	h2: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s76};
		line-height: ${props => props.theme.lineHeight.p80};
		font-weight: ${props => props.theme.fontWeight.medium};
	`,
	h2Tablet: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s26};
		line-height: ${props => props.theme.lineHeight.p34};
	`,
	h3: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s64};
		line-height: ${props => props.theme.lineHeight.p80};
		font-weight: ${props => props.theme.fontWeight.medium};
	`,
	h3Tablet: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s26};
		line-height: ${props => props.theme.lineHeight.p28};
	`,
	h4: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s38};
		line-height: ${props => props.theme.lineHeight.p50};
		font-weight: ${props => props.theme.fontWeight.medium};
	`,
	h4Tablet: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s26};
		line-height: ${props => props.theme.lineHeight.p28};
	`,
	paragraph: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s36};
		line-height: ${props => props.theme.lineHeight.p50};
	`,
	paragraphTablet: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s18};
		line-height: ${props => props.theme.lineHeight.p26};
	`,
	button: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s16};
		line-height: 1;
	`,
	link: css`
		font-family: ${props => props.theme.fontFamily.main};
		font-size: ${props => props.theme.fontSize.s24};
		line-height: 1.625;
	`,
};
