exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-fees-tsx": () => import("./../../../src/pages/fees.tsx" /* webpackChunkName: "component---src-pages-fees-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ipo-tsx": () => import("./../../../src/pages/ipo.tsx" /* webpackChunkName: "component---src-pages-ipo-tsx" */),
  "component---src-pages-personal-tsx": () => import("./../../../src/pages/personal.tsx" /* webpackChunkName: "component---src-pages-personal-tsx" */),
  "component---src-pages-upload-tsx": () => import("./../../../src/pages/upload.tsx" /* webpackChunkName: "component---src-pages-upload-tsx" */),
  "component---src-pages-vulnerability-disclosure-program-tsx": () => import("./../../../src/pages/vulnerability-disclosure-program.tsx" /* webpackChunkName: "component---src-pages-vulnerability-disclosure-program-tsx" */),
  "component---src-templates-page-info-tsx": () => import("./../../../src/templates/page-info.tsx" /* webpackChunkName: "component---src-templates-page-info-tsx" */)
}

