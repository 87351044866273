import { createGlobalStyle, css } from 'styled-components';

export const CookieStyles = createGlobalStyle<{ isAr: boolean, dark: boolean}>`

	.CookieConsent--container {
		bottom: 0;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		display: flex;
		top: unset !important;
		height: unset !important;
		background-color: ${props => props.theme.color.linen} !important;
		padding: ${props => `0 ${props.theme.space.s64}`};

		${props => props.theme.media.largeTablet} {
			padding-left: ${props => props.theme.space.s32};
			padding-right: ${props => props.theme.space.s32};
		}

		${props => props.theme.media.mobile} {
			padding-left: ${props => props.theme.space.s20};
			padding-right: ${props => props.theme.space.s20};
		}

		${({ isAr }) => isAr && css`
			direction: rtl;
		`}
		${({ dark }) => dark && css`
			filter: grayscale(1);
		`}
	}

	.CookieConsent--wrapper {
		position: unset !important;
		background: transparent !important;
		justify-content: center !important;
		margin: auto;
		padding: 25px 0;
		max-width: ${props => props.theme.sectionWidth.narrow};
		align-items: center !important;
		gap: 24px;

		${props => props.theme.media.tablet} {
			position: relative !important;
			padding: 20px 0;
			gap: 20px;
		}
	}

	.CookieConsent--content {
		display: flex;
		width: fit-content;
		align-items: center;
		gap: 20px;
		margin: 0 !important;
		flex: unset !important;

		${props => props.theme.media.tablet} {
			flex-direction: column;
			gap: 16px;

			svg {
				width: 26px;
				height: 26px;
			}
		}
	}

	.CookieConsent--accept-button {
		color: ${props => props.theme.color.darkBlue} !important;
		background-color: ${props => props.theme.color.linenLight} !important;
		font-size: ${props => props.theme.fontSize.s18} !important;
		padding: 10px 40px !important;
		font-weight: normal;
		border-radius: 8px !important;
		margin: 0 24px !important;

		${({ isAr }) => isAr && css`
			direction: rtl;
		`}

		/* &:hover {
			background-color: ${props => props.theme.color.darkBlue} !important;
			color: ${props => props.theme.color.white} !important;
		} */
	}
`;
