import React, { useRef } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { useIntl } from 'gatsby-plugin-intl';

import { Text } from '@/fatcat-ui-library/components/Atoms/Text';

type TBoxButton =
	{
		inputSize: string
		type?: 'radio' | 'checkbox'
		alignItems?: 'center' | 'flex-start' | 'flex-end'
		top?: string
		id: string
		name: string
		text: string | React.ReactElement
		fontSize?: keyof DefaultTheme['fontSize']
		textColor?: keyof DefaultTheme['textColor']
		boxColor?: keyof DefaultTheme['textColor']
		lineHeight?: keyof DefaultTheme['lineHeight']
		onChange: (e: any) => void
		value: string
		required?: boolean
		checked?: boolean
	};

type TBoxWrapper = {
	$size: string
	$align: TBoxButton['alignItems']
	$top: TBoxButton['top']
	isAr: boolean
	$boxColor?: TBoxButton['boxColor']
}

const BoxWrapper = styled.label <TBoxWrapper>`
	display: flex;
	width: fit-content;
	gap: 20px;
	align-items: ${props => props.$align};
	cursor: pointer;

	input {
		position: absolute !important;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	div {
		display: block;
		position: relative;
		width: 100%;
		top: ${props => props.$top};
		min-width: ${props => props.$size};
		max-width: ${props => props.$size};
		height: ${props => props.$size};
		border-radius: 4px;
		border: ${props => `1px solid ${props.theme.color[props.$boxColor]}`};
	}
	input:checked ~ div::after {
		content: '';
		display: block;
		position: absolute;
		pointer-events: none;
		border-radius: 2px;
		background-color: ${props => props.theme.color[props.$boxColor]};
		width: calc(100% - 6px);
		height: calc(100% - 6px);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const BoxButton: React.FC<TBoxButton> = (props) => {
	const inputRef = useRef(null);
	const {
		inputSize,
		alignItems,
		onChange,
		type,
		id,
		top,
		name,
		text,
		fontSize,
		textColor,
		boxColor,
		value,
		lineHeight,
		required,
		checked,
	} = props;
	const { locale } = useIntl();
	const isAr = locale === 'ar';

	const handeLabel = (e) => {
		if (e.code === 'Enter') {
			inputRef.current.click();
		}
	};

	return (
		<BoxWrapper
			$size={inputSize}
			$align={alignItems}
			$top={top}
			$boxColor={boxColor}
			isAr={isAr}
			htmlFor={id}
			tabIndex={0}
			onKeyPress={handeLabel}
		>
			<input
				ref={inputRef}
				tabIndex={-1}
				id={id}
				type={type}
				name={name}
				onChange={onChange}
				value={value}
				required={required}
				checked={checked ? true : false} // eslint-disable-line
			/>
			<div tabIndex={-1} />
			<Text
				$width="fit-content"
				$display="block"
				$textColor={textColor}
				$fontSize={fontSize}
				$lineHeight={lineHeight}
			>
				{text}
			</Text>
		</BoxWrapper>
	);
};

BoxButton.defaultProps = {
	fontSize: 's16',
	textColor: 'white',
	boxColor: 'white',
	lineHeight: 's133',
	type: 'checkbox',
	alignItems: 'center',
	top: '0',
	required: false,
	checked: false,
};

export default BoxButton;
