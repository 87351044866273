import { css } from 'styled-components';

export const ULLIST_STYLE = {
	base: css`
		padding: 0;
		li {
			list-style-type: disc;
			list-style-position: inside;
			max-width: 95%;
			margin-left: 20px;
			margin-bottom: 20px;
		}
		ul {
			margin-top: 20px;
		}
		ul li {
			list-style-type: circle;
		}
	`,
};

export const OLLIST_STYLE = {
	base: css`
		padding: 0;
		li {
			max-width: 95%;
			margin-left: 20px;
			margin-bottom: 20px;
		}

		> li {
			counter-increment: item;
			position: relative;
			&:before {
				content: counter(item) ". ";
				position: initial;
				font-size: ${props => props.theme.fontSize.s26};
				font-weight: normal;
				text-align: initial;
				display: inline;
				width: initial;
				margin-left: 0;
				padding-top: 0;

				${props => props.theme.media.tablet} {
					font-size: ${props => props.theme.fontSize.s20};
				}
			}
			font-size: ${props => props.theme.fontSize.s18};
		}
		ul li {
			list-style-type: none;
		}
		ul {
			margin-top: 20px;
		}
	`,
	noStyle: css``,
};
