import { css } from 'styled-components';

export const LINK_STYLE = {
	header: css`
		font-size: ${props => props.theme.fontSize.s16};
		line-height: ${props => props.theme.lineHeight.p20};
	`,
	grey: css`
		color: ${props => props.theme.color.grey};
		font-size: ${props => props.theme.fontSize.s30};
		line-height: ${props => props.theme.lineHeight.s100};
		${props => css`
			${props.theme.media.largeTablet} {
				font-size: ${props => props.theme.fontSize.s18};
				line-height: ${props => props.theme.lineHeight.p26};
			}
		`}
	`,
	headerDropdown: css`
		font-size: ${props => props.theme.fontSize.s28};
		line-height: ${props => props.theme.lineHeight.p40};
	`,
	purple: css`
		color: ${props => props.theme.color.purple};
		&:hover {
			opacity: 0.6;
		}
	`,
	noStyle: css`
	`,
};
